

#msacwl-slider-1{
   overflow: hidden;
   .msacwl-gallery-caption {
       background-color: #004a7e;
       color: #fff;
       padding: 5px 5px 5px 5px;
       text-align: center;
       position: absolute;
       bottom: 0;
       left: 0;
       right: 0;
       font-size: 14px;
       line-height: 20px;
    }
    .msacwl-gallery-slider .slick-dots, .msacwl-gallery-carousel .slick-dots {

    bottom: -13px;

}
}

#slider_5300{
   width: 100%;
    position: relative;
}

.sa_owl_theme .owl-nav {
    display: block !important;
    position: absolute;
    top: 50%;
    width: 100%;
}

#programmes{
   div[class^="col-"]{
      //background-color: red;
   }
   .h-100{
      display: flex;
      justify-content: center; /* align horizontal */
      align-items: center; /* align vertical */
   }
   .box{
      transition: all 0.5s ease-in-out;
      border:0;
      cursor: pointer;
      &:hover{z-index: 999;}
      img{
         transition: all 0.3s ease-in-out;;
         z-index: 1;
         &:hover{
            transform: scale(1.3) rotate(-5deg);
            z-index: 999;
         }
      }
      &:hover{
         //border: 2px solid blue;
         //border-radius: 10px;
         //background-color: blue;

      }

   }
}
#wrapper{
   width: 100% !important;
}
#nouvelle-section-accueil{
   margin-bottom: 60px;
}


#nouvelles{
   h3{
      font-size: 20px;
   }
   .lire-plus{
      font-size: 14px;
      display: inline-block;
      float: right;
   }
   p{
      margin-bottom: 0;
   }
}
.ligne-nouvelles{
   overflow: hidden;
   margin-bottom: 30px;
}
#part{
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   min-width: 100px;
   align-items: center;

.col-om{
   padding:0 15px 0 15px;
   flex: 1 1 0px;
}

}

#menu-item-3355{
   display: none;
}
